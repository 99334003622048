<div class="d-flex flex-row row align-items-stretch">
    <div class="d-flex flex-column col-md-7 align-items-stretch">
        <div class="d-flex info-highlight mt-3">
            <div class="row w-100">
                <div class="col-8 w-100 d-inline-flex align-items-center">
                    <div class="d-flex align-items-center">
                        <i class="fas fa-user"></i>
                        <span class="pl-12">
                            <p class="info-label">{{'T4.GENERIC.INTHENAMEOF' | translate | uppercase}}</p>
                            <p class="info-data">
                                <a href="#"
                                (click)="linkToCustomer(orderDetails.CustomerId)"><t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="orderDetails.OrderIdentificationCustomer"></t4-customer-identification-control></a>
                            </p>
                        </span>
                    </div>
                </div>
                <div class="col-4 w-100 d-inline-flex align-items-center">
                    <div class="d-flex align-items-center">
                        <i class="fas fa-calendar-alt"></i>
                        <span class="pl-12">
                            <p class="info-label">{{'T4.ORDERHEADER.ORDERDATE' | translate | uppercase}}</p>
                            <p class="info-data">
                                <span>{{orderDetails.OrderDate | localDateTime}}</span>
                            </p>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex info-highlight mt-3">
            <div class="row w-100">
                <div class="col-4 w-100 d-inline-flex align-items-center">
                    <div class="d-flex align-items-center w-100">
                        <i class="fas fa-cash-register"></i>
                        <span class="pl-12 w-100">
                            <p class="info-label">{{'T4.ORDERHEADER.DELIVERYMETHOD' | translate}}</p>
                            <p *ngIf="!isInEditMode" class="info-data">{{orderDetails.DeliveryMethodName | replaceOnEmpty}}</p>
                            <button *ngIf="isInEditMode" class="btn btn-warning btn-styling border dropdown-toggle mw-100" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-angle-down p-0 m-0"></i>
                                <p class="info-label d-inline-flex">{{ orderDetails.DeliveryMethodName | replaceOnEmpty: 'SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.DELIVERY-METHOD' | translate }}</p>
                            </button>

                            <div #deliveryMethodDropdown class="dropdown-menu p-0 w-100" aria-labelledby="dropdownMenuButton" x-placement="bottom-start"
                            style="position: absolute; transform: translate 3d(0px, 41px, 0px); top: 0px; left: 0px; will-change: transform;">
                                <ng-select [isOpen]="true" aria-labelledby="dropdownMenuButton" [items]="deliveryMethods" bindLabel="Name" bindValue="Id" placeholder="{{ 'SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.DELIVERYMETHOD' | translate }}" [(ngModel)]="orderDetails.DeliveryMethodId"
                                [clearable]="false" notFoundText="{{ 'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" (change)="onDeliveryMethodChange($event)"></ng-select>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-4 w-100 d-inline-flex align-items-center">
                    <div class="d-flex align-items-center w-100">
                        <i class="fas fa-sticky-note"></i>
                        <span class="pl-12 w-100">
                            <p class="info-label">{{'T4.ORDERHEADER.REFERENCE' | translate | uppercase}}</p>
                            <p *ngIf="!isInEditMode" class="info-data info-data--wrap">{{orderDetails.Reference  | replaceOnEmpty }}</p>
                            <button *ngIf="isInEditMode" class="btn btn-warning btn-styling" (click)="clickAction('reference')">
                                <i class="fas fa-edit align-baseline"></i>
                                <p class="info-label d-inline-flex">{{transform(orderDetails.Reference, 12)  | replaceOnEmpty }}</p>
                            </button>
                        </span>
                    </div>
                </div>
                <div class="col-4 w-100 d-inline-flex align-items-center">
                    <div class="d-flex align-items-center">
                        <i class="fas fa-ticket-alt"></i>
                        <span class="pl-12">
                            <p class="info-label">{{'T4.ORDERHEADER.NOOFTICKETS' | translate | uppercase}}</p>
                            <p class="info-data" data-toggle="tooltip" data-placement="bottom" title="{{orderDetails.AmountPayed}}">{{totalTickets}}</p>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex info-highlight mt-3">
            <div class="row w-100">
                <div class="col-4 w-100 d-inline-flex align-items-center">
                    <div class="d-flex align-items-center">
                        <i class="fas fa-wallet"></i>
                        <span class="pl-12">
                            <p class="info-label">{{'T4.ORDERHEADER.PRIMARYPAYMENTMETHOD' | translate | uppercase}}</p>
                            <p class="info-data">{{getPrimaryPaymentMethod() }}</p>
                            <div #paymentMethodDropdown class="dropdown-menu p-0 w-100" aria-labelledby="dropdownMenuButton" x-placement="bottom-start"
                            style="position: absolute; transform: translate 3d(0px, 41px, 0px); top: 0px; left: 0px; will-change: transform;">
                                <ng-select [isOpen]="true" aria-labelledby="dropdownMenuButton" [items]="paymentMethods" bindLabel="Name" bindValue="Id" placeholder="{{ 'SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.PAYMENTMETHOD' | translate }}" [(ngModel)]="orderDetails.UsedPaymentMethods"
                                [clearable]="false" notFoundText="{{ 'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" (change)="onPaymentMethodChange($event)"></ng-select>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="col-4 w-100 d-inline-flex align-items-center">
                    <div class="d-flex align-items-center">
                        <i class="fas fa-credit-card"></i>
                        <span class="pl-12">
                            <p class="info-label">{{'T4.ORDERHEADER.PENDING' | translate | uppercase}}</p>
                            <p class="info-data">{{orderDetails.PendingPaymentBalance | currency:'EUR'}}</p>
                        </span>
                    </div>
                </div>
                <div class="col-4 w-100 d-inline-flex align-items-center">
                    <div class="d-flex align-items-center">
                        <i class="fas fa-hand-holding"></i>
                        <span class="pl-12">
                            <p class="info-label">{{'T4.ORDERHEADER.PAYMENTSTATUS.OUTSTANDING' | translate | uppercase}}</p>
                            <p class="info-data">{{orderDetails.Balance | currency:'EUR'}}</p>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column col-md-5 align-items-stretch">
        <div class="d-flex info-highlight mt-3" [style.height]=getBoundingClientRect()>
            <div class="w-100">
                <p class="info-data">{{'T4.ORDERHEADER.SUMMARY' | translate | uppercase}}</p>
                <hr class="m-0">
                <div class="row">
                    <div class="col-md-7">
                        <p class="info-label info-sub">{{'T4.ORDERHEADER.SUBTOTAL' | translate | uppercase}}</p>
                    </div>
                    <div class="col-md-5 text-right">
                        <p class="info-label info-sub">{{orderDetails.ProductsSubTotalAmount  | currency:'EUR'}}</p>
                    </div>
                    </div>
                <div class="row">
                    <div class="col-md-7">
                        <p class="info-label info-sub">{{'T4.ORDERHEADER.DELIVERYCOSTS' | translate | uppercase}}</p>
                    </div>
                    <div class="col-md-5 text-right">
                        <p class="info-label info-sub">{{orderDetails.DeliveryMethodSubTotalAmount  | currency:'EUR'}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7">
                        <p class="info-label info-sub">{{'T4.ORDERHEADER.PAYMENTMETHOD' | translate | uppercase}}</p>
                    </div>
                    <div class="col-md-5 text-right">
                        <p class="info-label info-sub">{{orderDetails.PaymentMethodSubTotalAmount  | currency:'EUR'}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7">
                        <p class="info-data info-sub">{{'T4.ORDERHEADER.COSTS' | translate | uppercase}}</p>
                    </div>
                    <div class="col-md-5 text-right">
                        <p class="info-data info-sub">{{orderDetails.PaymentMethodSubTotalAmount + orderDetails.ProductsSubTotalAmount + orderDetails.PaymentMethodSubTotalAmount  | currency:'EUR'}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7">
                        <p class="info-label info-sub">{{'T4.ORDERHEADER.DISCOUNTS' | translate | uppercase}}</p>
                    </div>
                    <div class="col-md-5 text-right">
                        <p class="info-label info-sub">{{orderDetails.DiscountSubTotalAmount  | currency:'EUR'}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7">
                        <p class="info-label info-sub">{{'T4.ORDERHEADER.CREDIT' | translate | uppercase}}</p>
                    </div>
                    <div class="col-md-5 text-right">
                        <p class="info-label info-sub">{{calculateCredit() | currency: 'EUR'}}</p>
                    </div>
                </div>
                <hr class="m-0">
                <div class="row">
                    <div class="col-md-7">
                        <p class="info-data">{{'T4.ORDERHEADER.TOTALAMOUNT' | translate | uppercase}}</p>
                    </div>
                    <div class="col-md-5 text-right">
                        <p class="info-data">{{orderDetails.TotalInclVat  | currency:'EUR'}}</p>
                    </div>
                </div>
                <div class="card card-default w-100 mt-2 sale-channel-card">
                    <div class="row d-flex d-flex-row align-items-stretch">
                        <div class="col-8 w-100 d-inline-flex align-items-center">
                            <div class="d-flex align-items-center w-100">
                                <i class="fas fa-cash-register"></i>
                                <span class="pl-12 w-100">
                                    <p class="info-label">{{'T4.ORDERHEADER.SALESCHANNEL' | translate}}</p>
                                    <p *ngIf="!isInEditMode" class="info-data">{{orderDetails.SalesChannelName}}</p>
                                    <button *ngIf="isInEditMode" class="btn dropdown-toggle btn-warning border btn-sm px-2 py-1 mw-100" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-ticket-alt"></i>
                                        {{ orderDetails.SalesChannelName | replaceOnEmpty: 'SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.SALESCHANNEL' | translate }}
                                    </button>

                                    <div class="dropdown-menu p-0 w-100" aria-labelledby="dropdownMenuButton" x-placement="bottom-start"
                                    style="position: absolute; transform: translate 3d(0px, 41px, 0px); top: 0px; left: 0px; will-change: transform;">
                                        <ng-select [isOpen]="true" aria-labelledby="dropdownMenuButton" [items]="salesChannels" bindLabel="Name" bindValue="Id" placeholder="{{ 'SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.SALESCHANNEL' | translate }}" [(ngModel)]="orderDetails.SalesChannelId"
                                        [clearable]="false" notFoundText="{{ 'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" (change)="onSalesChannelChange($event)"></ng-select>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="col-4 w-100 d-inline-flex align-items-center flex-row-reverse">
                            <span class="pl-12">
                                <p class="info-label">{{'T4.ORDERHEADER.PAYMENTSTATUS' | translate}}</p>
                                <p class="info-data">
                                <span class="text-right">
                                    <span *ngIf="orderDetails.Payed" class="chipbox chipbox-closed">{{'T4.ORDERHEADER.PAYMENTSTATUS.PAID' | translate}}</span>
                                    <span *ngIf="!orderDetails.Payed" class="chipbox chipbox-open">{{'T4.ORDERHEADER.PAYMENTSTATUS.OUTSTANDING' | translate}}</span>
                                </span>
                                </p>
                            </span>
                            <i class="fas fa-stamp"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
