import { Component, Input, OnInit, ViewChild} from "@angular/core";
import { OrderDetailsView, OrderLineRevision, OrderManagementService, TicketSwapStatusEnum } from "../../../api";
import { ApplicationModuleHelperService } from "../../../services/application-module.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { EditOrderLineComponent } from "../../../dialogs/edit-order-line/edit-order-line.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-order-detail-tabs',
    templateUrl: './order-detail-tabs.component.html'
})
export class OrderDetailTabsComponent implements OnInit {
    @ViewChild('paymentOverview') paymentOverview;
    public isIbaActive: boolean = false;
    public secondaryTicketingActive: boolean = false;
    public _isInEditMode: boolean = false;
    public orderRevisions: OrderLineRevision[];
    public isSecundaryShop: boolean = false;

    @Input() orderDetails: OrderDetailsView;
    @Input() set isInEditMode(value: boolean){
      this._isInEditMode = value;
    }
    get isInEditMode(): boolean {
      return this._isInEditMode;
    }

    constructor(
      private readonly applicationModuleHelperService: ApplicationModuleHelperService,
      private readonly dialog: MatDialog,
      private readonly translate: TranslateService,
      private readonly orderManagementService: OrderManagementService,
    ) { }

    ngOnInit(): void {
      this.isSecundaryShop = this.orderDetails.HasSecondaryTicketStatus && this.orderDetails.SalesChannelName != 'Ticketshop secundair';

      this.applicationModuleHelperService.getActiveModules().subscribe((activeModules) => {
        if (activeModules !== null) {
          this.isIbaActive = activeModules.some((module) => module.toUpperCase() == 'IBA')
        }
      });

      this.orderManagementService.getOrderRevisions(this.orderDetails.Id).subscribe(revisions => {
        this.orderRevisions = revisions;
      });

      this.orderDetails.OrderLines.forEach(orderLine => {
        const acceptedStatuses = [TicketSwapStatusEnum.Offered, TicketSwapStatusEnum.Sold];
        if (orderLine.TicketSwapStatus != null && acceptedStatuses.includes(orderLine.TicketSwapStatus)) {
          this.secondaryTicketingActive = true;
        }
      })
    }

    onTabChange(e) {
      if (e.selectedTab.id === 'payment-history') {
        this.paymentOverview.loadPaymentHistoryForOrder(this.orderDetails.Id);
      }
    }

    openEditOrderLineDialog(e){
      const dialogRef: MatDialogRef<any> = this.dialog.open(EditOrderLineComponent, {
            width: '95%',
            maxWidth: '95%',
            disableClose: true,
            data: {
            title: this.translate.instant('COMPONENTS.CUSTOMER-TICKETS.OPEN-ORDER-DIALOG.TITLE.ORDER-DETAILS'),
            isReadOnly: true,
            orderLine: e,
            orderCustomerId: this.orderDetails.CustomerId
          }
      });
      dialogRef.afterClosed()
          .subscribe(_ => {
            this.orderManagementService.getOrderRevisions(this.orderDetails.Id).subscribe(revisions => {
              this.orderRevisions = revisions;
            });
          });
    }

    creditOrderLine(e) {
      this.orderManagementService.creditOrderLine(e).subscribe();
      this.orderManagementService.getOrderRevisions(this.orderDetails.Id).subscribe(revisions => {
        this.orderRevisions = revisions;
      });
    }

    removeDiscountCode(discountCodeId: number) {
      this.orderManagementService.removeDiscountCode(this.orderDetails.Id, discountCodeId).subscribe();
    }
}
