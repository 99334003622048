import { Component, Input, OnInit, ViewChild} from "@angular/core";
import { OrderLineRevision, OrderManagementService } from "../../api";
import { ListViewComponent, ListViewSelectionMode, PagedResult } from "@tymes4-shared";
import { MatDialog } from "@angular/material/dialog";
import { OrderRevisionDetailsComponent } from "app/shared/dialogs/order-revision-details/order-revision-details.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 't4-order-mutations',
    templateUrl: './order-mutations.component.html',
    styleUrls: ['./order-mutations.component.scss']
})
export class OrderMutationsComponent implements OnInit {
    @Input() revisions: any[] = [];
    @ViewChild(ListViewComponent) listVw : ListViewComponent;

    public listOptions = {
        search: false,
        showBarcodeSearch: false,
        pageSize: 7 ,
        noItemsText: 'T4.ORDERMUTATIONS.MUTATIONLINES.NOLINES',
        selectionMode: ListViewSelectionMode.None,
        disableSort: true,
        renderInCard: false,
        noSpacing: true,
        headers: [
            {sortField: null, label: 'T4.ORDERMUTATIONS.HEADER.MUTATION-NUMBER', hideOn: null},
            {sortField: null, label: 'T4.ORDERMUTATIONS.HEADER.DATE', hideOn: null},
            {sortField: null, label: 'T4.ORDERMUTATIONS.HEADER.EDITED-BY', hideOn: null},
            {sortField: null, label: 'T4.ORDERMUTATIONS.HEADER.CHANGES', hideOn: null, width: 338},
            {sortField: null, label: 'T4.ORDERMUTATIONS.HEADER.OLD-PRICE', hideOn: null},
            {sortField: null, label: 'T4.ORDERMUTATIONS.HEADER.NEW-PRICE', hideOn: null},
            {sortField: null, label: '', hideOn: null}
        ]
      };

    constructor(
        private orderManagementService: OrderManagementService,
        private dialog: MatDialog,
        private translate: TranslateService,
    ) { }

    ngOnInit(): void {
    }

    isLineRevision(line) {
        return (line.CreditChangeAction == 3 || line.CreditChange == undefined)
    }

    calculateLineChanges(line) {
      let changes: string = "";

      if (this.isLineRevision(line)) {
          const acceptedKeys = ['AppliedCredit', 'AppliedDiscountId', 'CustomerCalculatedName', 'CustomerPassePartoutId', 'DeliveryMethodId',
            'EntranceId', 'EventPlacementId', 'ManualPrice', 'NewBarcode', 'PriceVariationId', 'CustomerId'];

          Object.keys(line).filter((key)=> {
                if (line[key] != null && acceptedKeys.includes(key))
                {
                    changes += key + ' | ';
                }
            });
            changes = changes.slice(0, changes.length - 3)
            .replace('AppliedCredit', this.translate.instant('SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.APPLIED-CREDT'))
            .replace('AppliedDiscountId', this.translate.instant('SALES-ENTITY-MODIFICATION.DIALOG.LABEL.INPUT-DISCOUNT'))
            .replace('CustomerCalculatedName', this.translate.instant('REPURCHASE.RESERVATIONS.LIST.HEADER.CUSTOMER'))
            .replace('CustomerPassePartoutId', this.translate.instant('SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.CUSTOMER-PPT'))
            .replace('DeliveryMethodId', this.translate.instant('SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.DELIVERY-METHOD'))
            .replace('EntranceId', this.translate.instant('SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.ENTRANCE'))
            .replace('EventPlacementId', this.translate.instant('SALES.ORDERS.BLOCKED.DIALOG.DETAILS.LISTVIEW.HEADER.SECTION-ROW-SEAT'))
            .replace('ManualPrice', this.translate.instant('SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.MANUAL-PRICE'))
            .replace('NewBarcode', this.translate.instant('SALES-ENTITY-MODIFICATION.LISTVIEW.PLACEHOLDER.NEW-BARCODE'))
            .replace('CustomerId', this.translate.instant('ORDERS.NEW-ORDER.CONFIRM-ORDER.EDIT-ORDER-LINES.LISTVIEW.PLACEHOLDER.CUSTOMER'))
            .replace('PriceVariationId', this.translate.instant('ORDERS.NEW-ORDER.CONFIRM-ORDER.EDIT-ORDER-LINES.LISTVIEW.PLACEHOLDER.PRICE-VARIANT'));
        }
        else if (line.CreditChangeAction == 0) {
            changes = this.translate.instant('SALES-ENTITY-MODIFICATION.DIALOG.LABEL.PROFIT');
        }
        else if (line.CreditChangeAction == 1) {
            changes = this.translate.instant('SALES-ENTITY-MODIFICATION.DIALOG.LABEL.CASH-REFUND');
        }
        else if (line.CreditChangeAction == 2) {
            changes = this.translate.instant('SALES-ENTITY-MODIFICATION.DIALOG.LABEL.REFUND-CREDIT');
        }
        else {
            changes = this.translate.instant('SALES-ENTITY-MODIFICATION.DIALOG.LABEL.MANUAL-CREDIT');
        }

        if (line.Reason != undefined) {
            changes += (" | " + this.translate.instant('SALES-ENTITY-MODIFICATION.DIALOG.LABEL.REASON', {reason: line.Reason}))
        }

        return changes;
    }

    setListItems() {
        let d : PagedResult = {
            TotalRecords: this.revisions ? this.revisions.length: 0,
            Records: this.revisions
        }

        this.listVw.Data = d;
    }

    openRevisionDialog(revision: OrderLineRevision) {
        this.orderManagementService.getOrderLineRevisionDetails(revision.Id).subscribe(resp => {
            this.dialog.open(OrderRevisionDetailsComponent, {
                width: '95%',
                maxWidth: '95%',
                data: {revisions: resp}
            });
        });
    }
}
