import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { VenueRendererComponent } from "../../venue-renderer/venue-renderer.component";
import {EventService, OrderManagementService, PassePartoutService, SectionAvailabilityArgs, TicketTypeService, VenueAvailabilityArgs, VenueService} from "../../../api";
import { SalesSectionDetailsComponent } from "../../../dialogs/sales-section-details/sales-section-details.component";
import { TranslateService } from "@ngx-translate/core";
import { ListViewSelectionMode } from "@tymes4-shared";
import { SnackbarHelper } from "../../../helpers/snackbar-helper";

@Component({
    selector: 'app-order-line-position-dialog',
    templateUrl: './order-line-position-dialog.component.html',
    styleUrls: ['./order-line-position-dialog.component.scss']
})
export class OrderLinePositionDialogComponent implements OnInit {
  @ViewChild('listVw') listVw;
  @ViewChild(VenueRendererComponent) venueComponent: VenueRendererComponent;

  public listOptions = {
    search: true,
    showBarcodeSearch: false,
    pageSize: 5,
    noItemsText: 'GENERIC.LISTVIEW.NO-ITEMS.TEXT',
    searchTitle: 'SALES.ORDERS.NEW.SALES-SECTION-SELECTION.LISTVIEW.SEARCHTITLE',
    renderInCard: false,
    selectionMode: ListViewSelectionMode.DirectSelect,
    headers: [
      { sortField: 'Name', label: 'SALES.ORDERS.NEW.SALES-SECTION-SELECTION.LISTVIEW.HEADER.SECTION', width: 200, isDefaultSort: 'asc' },
      { sortField: null, label: 'SALES.ORDERS.NEW.SALES-SECTION-SELECTION.LISTVIEW.HEADER.AVAILABILITY' },
   ]
  };

  public customer;
  public orderLine;
  public positionType: string;
  public selectedItem: any;
  displayVenue = true;
  hasVenuePlan = true;
  inMapMode = true;
  selectedSellableSection = null;
  ticketTypes = [];
  venuePlanSvg = null;
  selectedEventIds = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef:  MatDialogRef<OrderLinePositionDialogComponent>,
    private venueService: VenueService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private ticketTypeService: TicketTypeService,
    private eventService: EventService,
    private passePartoutService: PassePartoutService,
    private orderManagementService: OrderManagementService,
    private snackbar: SnackbarHelper
  ) { }

  ngOnInit(): void {
    this.customer = this.passedData.customer;
    this.orderLine = this.passedData.orderLine;
    this.positionType = this.passedData.positionType;

    this.ticketTypeService.getAllTicketTypes().subscribe((data:any) =>{
      this.ticketTypes = data;
      this.refreshData();
    });
  }

  OnDataRequest(e) {
    let ids = [this.orderLine.EventId];

    if (this.orderLine.PassePartoutName) {
      this.passePartoutService.passePartoutSalesSearch(this.orderLine.PassePartoutName, e.pageNr, e.pageSize, 'Name', true).subscribe((resp:any) => {
        this.selectedEventIds = resp.Records[0].LinkedEventIds;
        ids = resp.Records[0].LinkedEventIds;
        this.eventService.findSellableSectionsForEvent(e.searchText, e.pageNr, e.pageSize, null, null, null, null, ids).subscribe((resp:any) => {
          this.listVw.Data = resp;
        });
      });
    }
    else {
      this.eventService.findSellableSectionsForEvent(e.searchText, e.pageNr, e.pageSize, null, null, null, null, ids).subscribe((resp:any) => {
        this.listVw.Data = resp;
      });
    }
  }

  refreshData() {
    this.listVw.refreshData();

    const ppId = this.orderLine.PassePartoutId === null ? null : this.orderLine.PassePartoutId;
    const eventIds = this.orderLine.EventId ? [this.orderLine.EventId] : [];
    if (!(ppId !== null || (eventIds !== null && eventIds.length > 0))) {
      this.displayVenue = false;
      return;
    }

    const args: VenueAvailabilityArgs = {
      EventIds: eventIds,
      PassePartoutId: ppId
    };

    this.venueService.getVenueAvailability(args).subscribe((availability) => {
      if (availability === null) {
        this.displayVenue = false;
        return;
      }

      this.venueService.getAvailabilityBasePlan(args).subscribe((planSVG: string) => {
        this.venuePlanSvg = planSVG;
        this.setAvailability(availability);
      });
    });
  }

  setAvailability(availability) {
    // method returns if there is a match on section
    this.displayVenue = true;
    this.hasVenuePlan = this.venueComponent.setVenuePlan(this.venuePlanSvg, availability);
    this.venueComponent.resizeMap();

     setTimeout(() => this.venueComponent.resizeMap(), 500);
  }

  sectionAvailability(section) {
    let result: string = '';

    for (let t of this.ticketTypes) {

      let c = this.getCount(section, t.Id);

      if (c > 0)  {
        if (result.length > 0) result += ', ';
        result += `${t.Name} (${c})`;
      }
    }

    return result.length == 0
      ? this.translate.instant('SALES.ORDERS.NEW.SALES-SECTION-SELECTION.NOT-AVAILABILE.MSG')
      : result;
  }

  getCount(section, ticketTypeId) {
    const c = section.TicketsPerTicketTypeId.filter(function(tptt){ if (tptt.Id == ticketTypeId) { return  tptt}});

    if (c != null && c.length > 0) {
      return c[0].Value;
    }

    return 0;
  }

  setMapMode (mapMode) {
    this.inMapMode = mapMode;

    if (this.inMapMode === false) {
      // research for the sections
      this.listVw.refreshData();
    }
  }

  freePosition() {
    const request = { OrderLineId: this.orderLine.Id };
    this.orderManagementService.freePosition(request).subscribe((data) => {
      this.snackbar.saved();
      this.dialogRef.close("free");
    },
    error => {
      let message;

     if (error.status == 404) {
      message = "The customer passe partout has no future matches associated with it.";
     }
     else {
      message = "An unkown error occured while trying to release the position.";
     }
     this.snackbar.open(message);
    });
  }

  OnItemSelectionChange(items) {
    //implementation for single selection mode
    if (items.length > 0) this.selectedItem = items[0];
    else this.selectedItem = null;
  }

  handleSectionClick(e) {
    let ppId = this.orderLine.PassePartoutId === null ? null : this.orderLine.PassePartoutId;
    let eventIds = this.orderLine.EventId ? [this.orderLine.EventId] : []


    let args : SectionAvailabilityArgs = {
      EventIds: eventIds,
      ParentVBBId: e.VenueBuildingBlockId
    }
    if (ppId)
    {
      args.PassePartoutIds = [ppId]
    }

    this.venueService.getTicketTypeForSection(args).subscribe(tts => {
       e.TicketsPerTicketTypeId = tts;
      this.selectSection(e);
    });
  }

  selectSection(section) {
    this.selectedSellableSection = section;
    this.openSectionDialog();
  }

  openSectionDialog() {
    let title = this.translate.instant('SALES.ORDERS.NEW.SALES-SECTION-SELECTION.DIALOG.DETAILS.TITLE', { sectionName: this.selectedSellableSection.Name});
    let sectionDialogRef: MatDialogRef<any> = this.dialog.open(SalesSectionDetailsComponent, {
      height: '95%',
      width: '98vw',
      maxWidth: '98vw',
      disableClose: true,
      data: { title: title, payload: {
        section: this.selectedSellableSection,
        ticketTypes: this.ticketTypes,
        selectedEvents: this.orderLine.EventId ? [this.orderLine.EventId] : [],
        selectedEventIds: this.orderLine.EventId ? [this.orderLine.EventId] : this.selectedEventIds,
        selectedPassePartouts: this.orderLine.PassePartoutId === null ?  [] : [{Id: this.orderLine.PassePartoutId}],
        selectedOrderLines: [],
        inEditMode: true
      } }
    });
    sectionDialogRef.afterClosed()
      .subscribe(orderLinesPerTicketType => {
        if (orderLinesPerTicketType && orderLinesPerTicketType[0]) {
          this.dialogRef.close([orderLinesPerTicketType[0].OrderLines[0], this.selectedSellableSection.VenueBuildingBlockId]);
        }
      }
    );
  }
}
