import {Component, Input, OnInit, Output} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CreditTransactionDialog } from "../credit-transaction-dialog/credit-transaction-dialog.component";
import { Customer } from "../../api";
import {EventEmitter} from "@angular/core";

@Component({
    selector: 'app-credit-actions-dropdown',
    templateUrl: './credit-actions-dropdown.component.html',
    styleUrls: ['./credit-actions-dropdown.component.scss']
  })
  export class CreditActionsDropdown implements OnInit {
    @Input() customer: Customer;
    @Output() creditChange: EventEmitter<boolean> = new EventEmitter();
    constructor(
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {

    }

    openDialog(type: string) {
      let title: string = "";
      if (type == 'revenue')
          title = 'CREDIT.DETAILDIALOG.TITLE.PROFIT'
      else if (type == 'bank')
          title = 'CREDIT.DETAILDIALOG.TITLE.BANK'
      else if (type == 'cash')
          title = 'CREDIT.DETAILDIALOG.TITLE.CASH'

      this.dialog.open(CreditTransactionDialog, { width: '525px', data: {title: title, type: type, customerId: this.customer.Id, credit: this.customer.TotalCredit} })
        .afterClosed()
        .subscribe(resp => {
          if (resp)
            this.creditChange.emit(true);
        });
    }

}
