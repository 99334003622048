<t4-list-view #listVw [options]="listOptions" (onDataRequest)="setListItems()">
  <ng-container *t4ListItem="let item">
    <ng-container>
      <td class="p-6">#{{ item.Id}}</td>
      <td class="p-6">{{item.Created | localDate}}</td>
      <td class="p-6">{{ item.UserCalculatedName | replaceOnEmpty }}</td>
      <td class="p-6">{{item.CreditChange ? ((item.CreditChange | currency:'EUR') + ' ') : ''}}{{ calculateLineChanges(item) | uppercase }}</td>
      <td class="p-6">{{!isLineRevision(item) ? '' : 0 | currency: 'EUR'}}</td> <!-- TODO: show old price here as currency, use the upcoming calculateLinePrice -->
      <td class="p-6">{{!isLineRevision(item) ? '' : 0 | currency: 'EUR'}}</td> <!-- TODO: show new price here as currency, use the upcoming calculateLinePrice -->
      <td class="d-flex flex-column p-6">
        <button class="btn btn-primary" (click)="openRevisionDialog(item)">
          <i class="fas fa-eye"></i>
          {{'T4.ORDERMUTATIONS.HEADER.SHOW' | translate | uppercase}}
        </button>
      </td>
    </ng-container>
  </ng-container>
</t4-list-view>