import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { localDateLongPipe, localTimePipe } from "@tymes4-shared";

@Component({
    selector: 'app-order-revision-details',
    templateUrl: './order-revision-details.component.html',
    styleUrls: ['./order-revision-details.component.scss']
  })
  export class OrderRevisionDetailsComponent implements OnInit {
    public original: any;
    public revision: any;
    public isIbaActive: boolean = false;
    public showOriginalPriceHover: boolean = false;
    public showRevisionPriceHover: boolean = false;
    public date: string;
    public localTime: string;
    public numberOfChanges: number = 0;
    constructor(
        @Inject(MAT_DIALOG_DATA) public passedData: any,
        public dialogRef: MatDialogRef<OrderRevisionDetailsComponent>,
        public translate: TranslateService,
        private localDatePipe: localDateLongPipe,
        private localTimePipe: localTimePipe,
    ) {}

    ngOnInit() {
      this.original = this.passedData.revisions[1];
      this.revision = this.passedData.revisions[0];
      this.date = this.localDatePipe.transform(this.revision.Created, true);
      this.localTime = this.localTimePipe.transform(this.revision.Created, true);
      var acceptedKeys = ['AppliedCredit', 'AppliedDiscountId', 'CustomerCalculatedName', 'CustomerPassePartoutId', 'DeliveryMethodId',
        'EntranceId', 'EventPlacementId', 'ManualPrice', 'NewBarcode', 'PriceVariationId'];

      this.numberOfChanges = Object.keys(this.revision).filter((key)=> this.revision[key] != null &&acceptedKeys.includes(key)).length;
    }

    constructCustomerIdentificationModel(revision) {
      return {
        CalculatedName : revision.CustomerCalculatedName,
        IsIdentified: revision.CustomerIsIdentified,
        CustomerNumber: revision.CustomerNumber,
      }
    }

    toggleOriginalPriceHover() {
      this.showOriginalPriceHover = !this.showOriginalPriceHover;
    }

    toggleRevisionPriceHover() {
      this.showRevisionPriceHover = !this.showRevisionPriceHover;
    }

    calculateLineBalance(line) {
      if (line?.ManualPrice !== null) {
        return line.ManualPrice - line.AppliedCredit;
      }

      if (line?.PriceVariationId !== null) {
        return line.PriceVariationId - line.AppliedCredit;
      }

      return null;
    }
}
